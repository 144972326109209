* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 17px;
  font-weight: 300;
}

a {
  cursor: pointer;
  color: #000;
}

a:hover {
  color: #000;
  text-decoration: none;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 200;
  text-transform: capitalize;
}

.root {
  min-height: 100%;
}

/* GENERIC BOOTSTRAP OVERRIDES */

.alert {
  border-radius: 0;
}

/* FORMS */

.form-control {
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 0;
  font-weight: 300;
  padding: .7rem .75rem;
}

:focus {
  outline: 0;
}

.form-control:focus {
  color: #000;
  background-color: #fff;
  border: none;
  outline: 0;
  box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.2);
}

form a:focus {
  border-bottom: 1px dotted #fff;
}

.mailing .form-control,
.myaccount .form-control,
.edituser .form-control,
.editcontacts .form-control,
.contact .form-control {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 0;
  font-weight: 300;
  padding: .7rem .75rem;
}

textarea {
  height: 200px;
}

/* BUTTONS */

.btn {
  font-weight: 400;
  border-radius: 32px;
  border: none;
  color: #fff;
  background-color: #00b1a9;
  text-transform: capitalize;
}

.btn-primary {
  color: #fff;
  background-color: #00b1a9;
  border-color: #00b1a9;
}

.btn-primary:hover {
  color: #fff;
  background-color: #00b1a9;
  border-color: #00b1a9;
  opacity: 0.8;
}

.btn.focus,
.btn:focus {
  box-shadow: inset 0 0 0 3px rgba(0, 0, 0, 0.2);
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  color: #000;
  background-color: #00b1a9;
  border-color: #00b1a9;
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #cecece;
  border-color: #cecece;
}

/* LOGIN */

.loginbg {
  background: #000000 no-repeat center center;
  background-size: cover;
}

.loginbg .gamelogo {
  background-repeat: no-repeat;
  background-size: contain;
  text-align: center;
  width: 90%;
  height: auto;
}

.loginbg .gamelogo img {
  width: auto;
  height: auto;
}

@media (max-width: 767px) {
  .loginbg .gamelogo img {
    width: 90%;
  }
}

.login {
  padding: 15px;
  background-color: #000;
  border-radius: 10px;
  width: 90%;
  max-width: 309px;
  margin-bottom: 27px;
}

.login .logo {
  text-align: center;
}

.login .logo img {
  width: 225px;
  height: auto;
  padding-top: 27px;
  padding-bottom: 42px;
}

.login .alert {
  margin-bottom: 0;
  font-size: 14px;
  padding: 6px 2px 6px 2px;
}

.login .alert {
  background: none;
  border: none;
  text-align: left;
}

.login .alert.alert-danger {
  color: #ff1a1a;
}

.login .alert.alert-success {
  color: #1aff1a;
}

.login .loginTitle {
  text-align: center;
  color: #fff;
  font-weight: 400;
}

.login form {
  height: auto;
  position: relative;
  padding: 20px 0 0 0;
  font-size: 16px;
}

.login form input.form-control {
  background-color: #282828 !important;
  color: #b2b2b2 !important;
  border: solid 1px #575757;
}

.login button {
  margin-top: 42px;
  margin-bottom: 6px;
  font-size: 18px;
}

.login .forgot {
  color: #b2b2b2;
  font-size: 16px;
  font-weight: normal;
}

.login .request-account {
  color: #b2b2b2;
  font-size: 15px;
  font-weight: 300;
}

.login .request-account a {
  font-weight: 400;
}

.login .affiliate-access a {
  color: #00b1a9;
  font-size: 16px;
  font-weight: 400;
}

/* NAV */

.nav-wrapper {
  background: #000000;
  padding: 10px;
}

nav.navbar {
  max-width: 1300px;
  margin: 0 auto;
  font-size: 16px;
  width: 100%;
}

.navbar-brand img {
  width: 100px;
}

.navbar-light .navbar-toggler {
  margin: 0 auto;
  border: none;
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255, 255, 255, .75);
}

.nav-item a {
  color: #fff;
  font-weight: 300;
}

.nav-item.small {
  padding-top: 4px;
  padding-left: 0;
}

.nav-item.small a {
  font-size: 12px;
  color: rgba(255, 255, 255, .75);
  font-weight: 300;
}

.nav-item.small span {
  margin-right: 4px;
}

.nav-item.small .fas {
  color: #00b1a9;
}

/* GAME DEMO */

.demo-wrapper {
  border-bottom: 1px solid #eaeaea;
}

.demo-wrapper .container {
  max-width: 90vw;
}

/* HEADER CAROUSEL */

.carousel-wrapper {
  background: #2B2C2B;
}

.carousel-wrapper .container {
  max-width: 1300px;
}

.carousel-control-next,
.carousel-control-prev {
  cursor: pointer;
  opacity: 1;
  width: 50px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 50px;
  height: 50px;
  background: transparent no-repeat center center;
  background-size: 60% 60%;
}

.carousel-control-prev-icon {
  background-image: url(../img/arrow-left.svg);
}

.carousel-control-next-icon {
  background-image: url(../img/arrow-right.svg);
}

.carousel-control-next:focus,
.carousel-control-prev:focus {
  opacity: 1;
}

.carousel-caption {
  width: 380px;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  right: initial;
  bottom: initial;
  top: 50%;
  transform: translateY(-50%);
  left: 15%;
  z-index: 10;
  padding: 40px;
  color: #fff;
  text-align: left;
}

.carousel-caption h1 {
  color: #00b1a9;
}

.carousel-caption .btn-lg {
  min-width: 200px;
  color: #00b1a9;
}

.featuredCarousel .carousel-item,
.featuredCarousel .carousel-inner,
.featuredCarousel .carousel-wrapper {
  min-height: 362px;
}

.featuredCarousel .carousel-indicators.hidden {
  display: none;
}

/* FEATURED */

.featured-wrapper {
  background: #F4F5F4;
  padding-top: 80px;
  padding-bottom: 20px;
}

.featured-wrapper .col-md-6 {
  padding-right: 40px;
  padding-left: 40px;
}

.featured-item {
  position: relative;
  text-align: center;
  margin-bottom: 60px;
  cursor: pointer;
}

.featured-item p {
  max-width: 70%;
  margin: 0 auto;
}

.featured-item img {
  width: 100%;
  max-width: 100%;
  display: block;
  margin-bottom: 20px;
  backface-visibility: hidden;
  transition: transform .3s ease-in-out;
}

.featured-item:hover img {
  opacity: 0.8;
  transform: scale(1.01);
}

.featured-item .tag {
  position: absolute;
  z-index: 999;
  top: 10px;
  right: -20px;
  background: #00b1a9;
  color: #fff;
  padding: 11px 15px;
  line-height: 1;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 400;
}

.featured-item .dotline {
  display: block;
  width: 120px;
  margin: 20px auto;
}

.featured-item h2 {
  color: #00b1a9;
}

/* BROSCHURE */

.broschure {
  background: #00b1a9;
  color: #fff;
}

.broschure a {
  margin-top: 16px;
  background: #fff;
  color: #00b1a9;
}

.broschure h2 {
  text-transform: uppercase;
}

.broschure .btn {
  color: black;
  text-transform: capitalize;
  width: 200px;
}

.broschure .btn.upload {
  color: #fff;
}

/* ROADMAP HIGHLIGHTS */

.roadmap-highlights {
  background: url(../img/roadmap-highlights-bg.png) no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
}

.roadmap-highlights a {
  margin-top: 16px;
  background: #00b1a9;
  color: #fff;
}

.roadmap-highlights h2 {
  text-transform: uppercase;
}

.roadmap-highlights .btn {
  color: #fff;
  text-transform: capitalize;
  width: 200px;
}

/* CATALOGUE */

.catalogue {
  background: url(../img/catalogue-bg.png) no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
}

.catalogue a {
  margin-top: 16px;
  background: #00b1a9;
  color: #fff;
}

.catalogue h2 {
  text-transform: uppercase;
}

.catalogue .btn {
  color: #fff;
  text-transform: capitalize;
  width: 200px;
}

/* ALL GAMES */

.all-games {
  background: #fff;
  padding-top: 80px;
  padding-bottom: 80px;
}

.all-games h1 {
  font-size: 50px;
  text-transform: uppercase;
}

.game-item {
  text-align: center;
  background: #fff;
  padding-bottom: 1px;
  margin-bottom: 30px;
  backface-visibility: hidden;
  transition: transform .3s ease-in-out;
  cursor: pointer;
}

.game-item img {
  max-width: 100%;
}

.game-item h2 {
  font-size: 22px;
  margin: 20px 0;
  text-transform: uppercase;
}

.game-item:hover {
  opacity: 0.8;
  transform: scale(1.01);
}

.admin-buttons button {
  text-transform: capitalize;
}

.admin-buttons button:nth-child(odd) {
  background: #33C1BA;
}

/* SINGLE GAME */

.single-wrapper {
  background: #F4F5F4;
  padding-top: 50px;
}

.single-wrapper>.container {
  position: relative;
  background: #fff;
  padding: 50px;
}

.info-item {
  margin-top: 30px;
  margin-bottom: 30px;
}

.game-buttons {
  margin-top: 30px;
  margin-bottom: 30px;
}

.game-buttons .btn-lg {
  min-width:160px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 400;
}

#gamecarousel {
  margin-top: 40px;
}

.single-close {
  position: absolute;
  top: 0;
  right: 0;
  background: #00b1a9;
  width: 40px;
  height: 40px;
  color: #fff !important;
  text-align: center;
  line-height: 37px;
  font-size: 35px;
  font-weight: 200;
  cursor: pointer;
}
.single-close:hover {
  color: #000 !important;
}

.single-close.game {
  background: none;
  color: gray !important;
}

.single-close.game:hover {
  color: black !important;
}

.modal-title .single-close {
  border-top-right-radius: .3rem;
}

/* NEWS */

.content-wrapper.padded {
  padding-top: 50px;
}

.content-wrapper.padded>.container {
  background: #fff;
  padding: 50px;
}

.news-list .news-item img {
  width: 30%;
  float: left;
  margin: 3px 20px 20px 0;
}

.news-list .news-item:first-child img {
  max-width: 100%;
  width: 100%;
  height: auto;
  float: none;
  margin: 0;
}

.news-list .news-item .date {
  background-color: #00b1a9;
  color: #fff;
  font-size: 14px;
  display: inline-block;
  padding: 5px 5px;
  margin: 0 0 5px;
  line-height: 14px;
}

.news-list .news-item:first-child .date {
  margin: 20px 0 5px;
}

.list-group-item {
  border: none;
  padding: 0;
}

.sidebar-list h2:after,
.news-item:after {
  content: " ";
  display: block;
  background: url(../img/dotline.png) repeat-x;
  background-size: 78%;
  margin-top: 20px;
  width: 100%;
  height: 10px;
}

.news-item:after {
  background-size: 36%;
  margin: 40px 0;
}

.sidebar-list .badge {
  padding: 0 1px 0 0;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  background-color: #00b1a9;
  font-weight: 200;
  color: #fff;
}

.news-list .news-item.archive {
  cursor: pointer;
}

.news-list .news-item.archive:hover {
  opacity: 0.8;
}

.news-list .news-item a {
  border-bottom: 1px solid #00b1a9;
}

.sidebar-list a {
  border-bottom: 1px solid transparent;
}

.sidebar-list a:hover {
  opacity: 0.8;
  border-bottom: 1px solid #00b1a9;
}

/* FOOTER */

footer {
  background: #2B2C2B;
  color: #fff;
  min-height: 100px;
  padding: 50px 5px 5px 5px;
}

footer a {
  color: #fff;
}

footer a:hover {
  color: #fff;
  border-bottom: 1px solid #00b1a9;
}

footer .version {
  font-size: 10px;
  height: 45px;
  opacity: 0.4;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

footer .version div {
  text-align: right;
}

@media (max-width: 767px) {
  /* menu */
  header {
    background: none;
  }

  header .header-buttons {
    right: 0;
    width: 100%;
  }

  header .logo img {
    max-width: 150px;
    margin: 60px 0 20px;
  }

  nav .navbar-nav {
    margin: 0 auto;
    text-align: center;
    margin-top: 20px;
  }

  /* games */
  .featured.container .col-md-6 {
    padding-right: 15px;
    padding-left: 15px;
  }

  .featured-item .tag {
    display: none;
  }

  /* single game */
  .game-info {
    text-align: center;
    margin-bottom: 50px;
  }
}

.asset-editor-divider {
  background: repeating-linear-gradient(135deg, #ffd600, #ffd600 10px, black 10px, black 20px);
  margin: 16px 0;
  padding: 8px;
}

.asset-editor-divider >span {
  background: #000;
  opacity: 0.9;
  color: #ffd600;
  font-weight: 400;
  padding: 2px 10px;
}

.asset-editor-form .form-control {
  border: 1px solid #aaa;
}

.editorsection {
  border: 1px solid #eee;
  padding: 8px;
  margin: 8px 0;
}

.editorsection-title {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 8px;
}

.editorsection .btn {
  text-transform: capitalize;
  min-width: 160px;
}

.editorsection .files .btn {
  min-width: 0px;
  color: #00b1a9;
  background-color: transparent;
}

span > a.btn.btn-lg.m-1.d-none.d-md-inline-block {
  color: #fff;
}
span > a.btn.btn-lg.m-1.d-none.d-md-inline-block:hover {
  color: #000;
}

.datetime-picker {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.datetime-picker .date {
  width: 110px;
}

.datetime-picker .time {
  width: 80px;
}

.datetime-picker .reset {
  text-align: left;
  margin: auto 10px;
  color: #00b1a9;
}

.datetime-picker .warning {
  text-align: left;
  padding-left: 1rem;
  color: red;
  flex: 1;
}

.progress-spinner {
  width: 100%;
  height: 100%;
  min-width: 22px;
  min-height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.progress-spinner .spinner {
  background: url(../img/relax-dots-black.svg) no-repeat;
  transform-origin: 50% 50%;
  transform: translate(-50%,-50%);
  width: 20px;
  height: 20px;
  animation: spin 666ms infinite linear;
}

.progress-spinner .spinner.white {
  background: url(../img/relax-dots-white.svg) no-repeat;
}

input:disabled:hover {
  cursor: not-allowed;
}

.tier {
  border-radius: 10px;
  padding: 0.375rem 0.5rem;
  font-weight: bold;
  margin: 0 4px;
}

.tier-selector {
  border-radius: 4px;
  opacity: 0.7;
}

.tier-selector.selected {
  opacity: 1.0;
}

.tier-selector:hover {
  cursor: pointer;
}

.tier-1 { background: #e6194b; }
.tier-2 { background: #3cb44b; }
.tier-3 { background: #ffe119; }
.tier-4 { background: #4363d8; }
.tier-5 { background: #f58231; }
.tier-6 { background: #42d4f4; }
.tier-7 { background: #f032e6; }
.tier-8 { background: #bfef45; }
.tier-9 { background: #fabebe; }
.tier-10 { background: #469990; }
.tier-11 { background: #e6beff; }
.tier-12 { background: #9A6324; }
.tier-13 { background: #fffac8; }
.tier-14 { background: #aaffc3; }
.tier-15 { background: #808000; }
.tier-16 { background: #ffd8b1; }
.tier-17 { background: #a9a9a9; }

.game-details h1 {
  text-transform: capitalize;
  color: #00b1a9;
}

.game-overview-item {
  color: #525252;
  background: #ebebeb;
  font-size: 14px;
}

.game-overview-item .title {
  font-weight: 400;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
}

.game-overview-item .specifier {
  text-transform: uppercase;
  font-size: 10px;
}

.privacyText {
  overflow-y: scroll;
  height: 30vh;
  width: 100%;
  resize: none;
  border: 1px solid #aaa;
  padding: 5px;
}
